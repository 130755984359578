
const toMoeny = (value: any, length: number = 2) => {
    if (!value) {
        return parseFloat('0').toFixed(length).toString();
    }
    let v = '';
    let x = parseFloat('0').toFixed(length).toString().substr(2);
    if (typeof value === 'number') {
        value = value.toString();
    }
    let val_arr = value.split('.');
    v = val_arr[0];
    if (val_arr.length === 2 && val_arr[1]) {
        x = val_arr[1];
        if (x.length > length) {
            x = x.substr(0, length);
        }
        if (x.length < length) {
            x = x + parseFloat('0').toFixed(length - x.length).toString().substr(2)
        }
        if (x.length === 1 && parseInt(x) > 0) {
            x = x + '0';
        }
    }
    if (v.indexOf(',') === -1) {
        v = v.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    }
    return v + '.' + x;
}

export function DataURLtoFile(data: any, type: any) {
    let bytes = window.atob(data), n = bytes.length, u8arr = new Uint8Array(n)
    while (n--) {
        u8arr[n] = bytes.charCodeAt(n)
    }
    return new Blob([u8arr], { type: type })
}

export function ExportExcel(data: any, type: any, name: string) {
    const _down = DataURLtoFile(data, type)
    const a = document.createElement('a')
    a.download = name
    a.href = window.URL.createObjectURL(_down)
    a.click()
    document.body.appendChild(a)
}

export default {
    toMoeny,
    ExportExcel
}
