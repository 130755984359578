import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";

Vue.use(VueRouter);

export const constantRoutes: RouteConfig[] = [
  {
    path: '/',
    component: () => import('@/views/index.vue')
  },
  {
    path: '/login',
    component: () => import('@/views/login.vue'),
    meta: {
      title: '登录',
      nologin: true
    }
  }
]


const router = new VueRouter({
  routes: constantRoutes,
});


export default router;
