import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import ElementUI from "element-ui";
import "@/styles/index.scss";
import filters from '@/utils/filter'
Vue.use(ElementUI);
Vue.config.productionTip = false;
Object.keys(filters).forEach(key => Vue.filter(key, filters[key]))
router.beforeEach((to: any, from, next) => {
  if (!to.meta.nologin) {
    if (!sessionStorage.getItem("token")) {
      next({
        path: '/login',
        query: {
          redirect: to.fullPath
        }
      })
      return
    }
  }
  next()
})

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
